/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// import 'intersection-observer'

export const onServiceWorkerUpdateFound = () => {
	// const answer = window.confirm(
	// 	`Мы обновили медиарешебник ` +
	// 	`Обновить страницу для загрузки новой версии?`
	// )
	// if (answer === true) {
  //   window.location.reload()
	// }
  
}
// export const registerServiceWorker = () => {
//   // console.log('я новый сервис воркер');
//   // requestNotificationPermission();
//   // return true
// }
// const firebase = require(`firebase/app`)
// export const onRouteUpdate = ({ location, prevLocation }) => {
//   console.log('new pathname', location.pathname)
//   console.log('old pathname', prevLocation ? prevLocation.pathname : null)
//   if (prevLocation = "/index.html") {
//     document.querySelector("body").classList.add("fromMain")
//   }
// }
export const onInitialClientRender = () => {
  console.log("ReactDOM.render has executed")
}
export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation === "/index.html") {
    document.querySelector("body").classList.remove("fromMain")
  }
  // console.log("Gatsby started to change location to", location.pathname.split("/"))
  // console.log("Gatsby started to change location from", prevLocation ? prevLocation.pathname : null)
}