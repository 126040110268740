module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132323964-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Медиарешебник","short_name":"AboutPeople","start_url":"/","background_color":"#303a4e","theme_color":"#d3a463","display":"minimal-ui","gcm_sender_id":"482941778795","gcm_sender_id_comment":"Do not change the GCM Sender ID","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"64a14000a6302850e2d32e730df2e871"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@prismicio/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"testwork","sharpKeys":[{},"profilepic"],"extraPageFields":"rubric","pages":[{"type":"News","match":"/elderly/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/newsTemplate.js"},{"type":"News","match":"/lgbtq/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/newsTemplate.js"},{"type":"News","match":"/transgender/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/newsTemplate.js"},{"type":"News","match":"/roma/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/newsTemplate.js"},{"type":"News","match":"/gender/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/newsTemplate.js"},{"type":"News","match":"/onko/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/newsTemplate.js"},{"type":"News","match":"/disability/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/newsTemplate.js"},{"type":"News","match":"/drugs/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/newsTemplate.js"},{"type":"News","match":"/migrants/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/newsTemplate.js"},{"type":"News","match":"/hiv/news/:uid","path":"/news","component":"/opt/build/repo/src/templates/newsTemplate.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
